import { Spinner } from "reactstrap";

import styles from "./Loading.module.css";
import { useState } from "react";

const Loading = () => {
  const [showHelpText, setShowHelpText] = useState(false);

  setTimeout(() => {
    setShowHelpText(true);
  }, 5000);

  return (
    <div className={styles.loadingContainer}>
      <div className={styles.spinnerContainer}>
        <h3 className={styles.title}>Blockposal</h3>
        <Spinner></Spinner>
        {showHelpText && (
          <p className={styles.title} style={{ marginTop: 16 }}>
            If this continues, please make sure no wallet pop ups are blocking
            the page
          </p>
        )}
      </div>
    </div>
  );
};

export default Loading;
