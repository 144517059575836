import React from "react";
import { conditionalRender, ConditionalWrapper } from "../../utils/helpers";
import { Navbar } from "../navbar";
import ProtectedRoute from "../protectedRoute";
import styles from "./Page.module.css";

type Props = {
  isProtected?: boolean;
  hideNavbar?: boolean;
  children: React.ReactNode;
};

const Page = ({ isProtected = true, hideNavbar, children }: Props) => {
  return (
    <ConditionalWrapper
      condition={isProtected}
      wrapper={(children: any) => <ProtectedRoute>{children}</ProtectedRoute>}
    >
      {conditionalRender(!hideNavbar, <Navbar />)}
      <div className={styles.page}>
        <div className={styles.scrollableContainer}>{children}</div>
      </div>
    </ConditionalWrapper>
  );
};

export { Page };
